<template>
  <div>
    <b-modal
      v-if="!isCheck"
      v-model="isShow"
      ref=""
      size="md"
      centered
      @hidden="hide()"
    >
      <template #modal-header>สร้างใบโอน</template>
      <h5 class="text-center text-bold my-2">ต้องการสร้างใบโอนหรือไม่?</h5>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <b-button @click="hide()" class="border-btn btn-w">ไม่สร้าง</b-button>

          <b-button
            class="submit-btn text-right btn-w"
            @click.prevent="isCheck = true"
            >สร้าง</b-button
          >
        </div>
      </template>
    </b-modal>
    <b-modal v-else v-model="isShow" ref="" size="xl" centered @hidden="hide()">
      <template #modal-header>Transfer</template>

      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(index)="{ item, index }">
          {{ index + 1 }}
        </template>
        <template v-slot:cell(product_image)="{ item }">
          <div class="position-relative picture-text pic-table">
            <div
              v-if="item.product_image"
              class="pic-box"
              v-bind:style="{
                'background-image': 'url(' + item.product_image + ')',
              }"
            ></div>
            <div v-else>
              <img
                class="pic-box pt-0"
                :src="default_image"
                alt="default Image"
                srcset=""
              />
            </div></div
        ></template>
        <template v-slot:cell(serial)="{ item, index }">
          {{ item.serial || "-" }}
        </template>
      </b-table>
      <!-- <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      /> -->
      <InputSelect
        v-if="!isMain"
        placeholder="การโอนสินค้า"
        title="การโอนสินค้า"
        v-model="form.transfer_document_id"
        valueField="document_id"
        textField="document_name"
        :options="listTransferDocument"
      >
        <template v-slot:option-first>
          <b-form-select-option :value="null" disabled
            >-- โอนจากสาขา (TRN) --</b-form-select-option
          >
        </template>
      </InputSelect>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <b-button @click="hide()" class="border-btn btn-w">Cancel</b-button>

          <b-button class="submit-btn text-right btn-w" @click.prevent="save()"
            >Transfer</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    // form: {
    //   required: true,
    // },
  },

  data() {
    return {
      form: { transfer_document_id: null },
      isShow: false,
      isCheck: false,
      isMain: false,
      isBusy: true,
      items: [],
      filter: { po_id: this.$route.params.id, search: "", page: 1, take: 5 },
      fields: [
        { label: "#", key: "index", thStyle: { width: "10%" } },

        { label: "รูปภาพ", key: "product_image", thStyle: { width: "10%" } },

        { label: "รหัสสินค้า", key: "barcode", thStyle: { width: "10%" } },
        {
          label: "ขื่อสินค้า",
          key: "product_name",
          thStyle: { width: "30%" },
        },
        {
          label: "Serial",
          key: "serial",
          thStyle: { width: "10%" },
        },

        { label: "น้ำหนัก", key: "weight", thStyle: { width: "10%" } },
        {
          label: "จำนวน",
          key: "received_quantity",
          thStyle: { width: "10%" },
        },
        { label: "Action", key: "action", thStyle: { width: "10%" } },
      ],
      id: this.$route.params.id,
      listTransferDocument: [],
    };
  },

  methods: {
    async showModal(isCheck, mainBranch) {
      this.isCheck = isCheck;
      this.isMain = isCheck;
      this.isShow = true;
      const res = await this.axios(`/po/${this.id}/availableTransferProduct`);
      if (res.data.result == 1) {
        this.items = res.data.detail;
      } else {
        this.errorAlert(res.data.message);
      }

      if (!this.isMain) {
        await this.getListTransferDocument();
      } else {
        this.form.transfer_document_id = mainBranch;
      }
      this.isBusy = false;
    },
    async getListTransferDocument() {
      const res = await this.axios.post(
        `/po/GetInventoryTransferDocumentList`,
        { po_id: this.id }
      );
      this.listTransferDocument = res.data.detail;
    },
    hide() {
      this.isShow = false;
    },

    async save() {
      this.$bus.$emit("showLoading");
      let payload = {
        po_id: this.id,
        transfer_document_id: this.form.transfer_document_id,
        is_createTransfer: true,
      };
      const res = await this.axios.post(`/po/TransferToWarehouse`, payload);
      if (res.data.result === 1) {
        this.successAlert();
        this.$emit("confirmCreate");
        this.hide();
      } else {
        this.errorAlert(res.data.message);
      }
      this.$bus.$emit("hideLoading");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-footer {
  justify-content: space-between;
}
.btn-save {
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.btn-cancel {
  color: #fff;
  background-color: #39424e;
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}

.btn-w {
  width: 100px;
  text-align: center !important;
}

::v-deep .table-responsive{
  max-height: 350px !important;

}
</style>
