<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div class="bg-white border-red p-2 p-lg-3 ">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(po_no)="{ item }">
                <router-link
                  :to="'/setting/inventory/purchase-order/detail/' + item.po_id"
                  ><span class="underline">{{ item.po_no }}</span></router-link
                >
              </template>

              <template v-slot:cell(created_time)="{ item }">
                <div>{{ item.created_time | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ item.created_time | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(remark)="{ item }">
               {{item.remark || '-'}}
              </template>
              <template v-slot:cell(goods_receipt_name)="{ item }">
                <div v-if="item.goods_receipt_name">
                  {{ item.goods_receipt_name }}
                </div>
                <div v-else>-</div>
              </template>
              <template v-slot:cell(inventory_transfer_name)="{ item }">
                <div v-if="item.inventory_transfer_name">
                  {{ item.inventory_transfer_name }}
                </div>
                <div v-else>-</div>
              </template>

              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <Pagination
          @handleChangeTake="handleChangeTake"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
  </div>
</template>
<script>
import OtherLoading from "@/components/loading/OtherLoading";

export default {
  components: {
    OtherLoading,
  },
  data() {
    return {
      isLoading: true,
      filter: {
        po_id: this.$route.params.id,
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      tabIndex: 0,
      statusList: [],
      fields: [
        {
          label: "Action",
          key: "action_name",
        },
        {
          label: "Number of SKU ",
          key: "number_sku",
        },
        {
          label: "Qty.",
          key: "quantity",
        },

        {
          label: "Staff",
          key: "created_by",
        },

        {
          label: "Receiver",
          key: "",
        },
        {
          label: "Receive Number",
          key: "goods_receipt_name",
        },
        {
          label: "Transfer Doc. Number",
          key: "inventory_transfer_name",
        },
        {
          label: "Remark",
          key: "remark",
        },
        {
          label: "Transaction Date",
          key: "created_time",
        },
      ],
      items: [],
      data: {
        total: 0,
        total_success: 0,
        total_pending: 0,
      },
    };
  },
  async created() {
    await this.getList();
  },

  methods: {
    async getList() {
      let payload = { ...this.filter };

      this.isBusy = true;
      const response = await this.axios.post(`/po/Log`, payload);

      this.rows = response.data.detail.total_count;
      this.items = response.data.detail.po_logs;
      this.isBusy = false;
      this.isLoading = false;
    },

    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },

    clearFilter() {
      this.filter = {
        search: "",
        status: 1, // null = all,1 = active,0=inactive
        page: 1,
        take: 10,
      };
      this.getList();
    },
  },
};
</script>
<style lang="scss">
.banner-image {
  max-width: 50px;
  height: auto;
  width: 100%;
  aspect-ratio: 1;
}
</style>
