<template>
  <div>
    <b-modal v-model="isShow" ref="" size="lg" centered @hidden="hide()">
      <template #modal-header>ตรวจรับ</template>

      <b-row>
        <b-col cols="8">
          <h2 class="mr-sm-4 header-tablepage">
            {{ selectedItem.product_name }}
          </h2>
        </b-col>
        <b-col cols="4" class="text-right">
          <button
            @click="exportTemplate"
            type="button"
            class="btn button cancel-btn mb-2 mr-2"
          >
            <font-awesome-icon icon="file-export" class="pointer" />
            <span class="btn-text">Export </span>
          </button>
          <button
            @click="modalImport = true"
            type="button"
            class="btn button submit-btn mb-2"
          >
            <font-awesome-icon icon="file-import" class="pointer" />
            <span class="btn-text">Import </span>
          </button>
        </b-col>
        <b-col cols="12">
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
          >
            <template v-slot:cell(serial)="{ item }">
              <InputText
                v-if="selectedItem.is_serial"
                v-model="item.serial"
                placeholder="Serial"
                class="input-tb mb-0 mb-0"
                type="text"
              ></InputText>
            </template>
            <template v-slot:cell(quantity)="{ item, index }">
        
              <InputText
                v-model="item.quantity"
                placeholder="จำนวน"
                type="number"
                class="input-tb mb-0"
                v-if="!selectedItem.is_serial"
                @input="(val, e) => limitQuantity(val, e, index)"
              ></InputText>
              <div v-else>
                {{
                  selectedItem.is_serial
                    ? 1
                    : selectedItem.quantity - selectedItem.received_quantity
                }}
              </div>
            </template>
            <template v-slot:cell(weight)="{ item, index }">
              <InputText
                v-model="item.format_weight"
                placeholder="น้ำหนัก"
                @onKeypress="handleKeyPress"
                type="text"
                class="input-tb mb-0"
                @input="(val, e) => formatCurrencyWeght(val, e, index)"
              ></InputText>
            </template>
            <template v-slot:cell(cost)="{ item, index }">
              <InputText
                v-model="item.format_cost"
                placeholder="ราคาต้นทุน (ต่อชิ้น)"
                @onKeypress="handleKeyPress"
                @input="(val, e) => formatCurrencyCost(val, e, index)"
                type="text"
                decimalPoint="2"
                class="input-tb mb-0"
              ></InputText>
            </template>
            <template v-slot:cell(total_cost)="{ item }">
              {{ (item.cost * item.quantity) | numeral("0,0.00") }}
            </template>
            <template v-slot:cell(action)="{ item }">
              <div v-if="selectedItem.is_serial">
                <b-button
                  variant="icon"
                  class="px-0"
                  @click="deleteProduct(item)"
                >
                  <b-icon icon="trash-fill"></b-icon>
                </b-button></div
            ></template>
          </b-table>
        </b-col>
        <b-col cols="12" v-if="selectedItem.is_serial">
          <div class="text-center card-dashed py-3">
            <button
              type="button"
              class="btn button submit-btn"
              @click="addItem()"
            >
              <font-awesome-icon icon="plus-square" class="pointer" />
              <span class="btn-text">เพิ่มไอเทม </span>
            </button>
          </div>
        </b-col>
        <b-col cols="6" class="mt-3">
          <div class="d-flex align-items-center">
            <p class="mr-2 text-width">Invoice Number</p>
            <InputText
              v-model="form.tax_invoice_no"
              type="text"
              placeholder="Invoice Number"
              class="mb-2 custom-input-text"
            ></InputText>
          </div>
        </b-col>
        <b-col cols="6" class="text-right mt-3">
          <p class="text-secondary">
            ต้นทุนรับเข้าครั้งนี้ :
            <span class="text-black"
              >{{ totalCost | numeral("0,0.00") }} บาท</span
            >
          </p>
        </b-col>
        <b-col cols="6">
          <div class="d-flex align-items-center">
            <p class="mr-2 text-width">Invoice Date</p>
            <InputDatePickerFilter
              textFloat=""
              class="mb-1"
              name="valid-form"
              ref="valid-form"
              format="dd/MM/yyyy "
              v-model="form.tax_invoice_date"
              type="date"
            />
          </div>
        </b-col>
        <b-col cols="6" class="text-right">
          <p class="text-secondary">
            รอตรวจรับทั้งหมด :
            <span class="text-black"
              >{{
                selectedItem.quantity - selectedItem.received_quantity
              }}
              รายการ</span
            >
          </p>
        </b-col>
        <b-col cols="6">
          <InputTextArea
            textFloat="หมายเหตุการรับ"
            type="text"
            class="mt-0 custom-input"
            placeholder="หมายเหตุการรับ"
            rows="3"
            name="message"
            v-model="form.remark"
          ></InputTextArea>
        </b-col>
        <b-col cols="6" class="text-right">
          <p class="text-secondary">
            รับเข้าครั้งนี้ :
            <span class="text-black"
              >{{
                selectedItem.is_serial
                  ? items.length
                  : selectedItem.quantity - selectedItem.received_quantity
              }}
              รายการ</span
            >
          </p>
          <p class="text-secondary">
            ขาดอีก :
            <span class="text-danger"
              >{{
                selectedItem.is_serial
                  ? selectedItem.received_available - items.length
                  : 0
              }}
              รายการ</span
            >
          </p>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-row>
          <b-col class="text-left">
            <b-button @click.prevent="hide()" class="border-btn"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button class="submit-btn" @click="save()">Save</b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-modal
      v-model="modalImport"
      no-close-on-backdrop
      centered
      title="Import Product"
      hide-footer
    >
      <div>
        <b-row class="no-gutters">
          <b-col md="12">
            <UploadFileV2
              textFloat="File"
              placeholder="Please Choose File"
              format="excel"
              name="file"
              :fileName="fileName"
              text="*Please upload only file .xlsx less than 10 MB"
              v-on:onFileChange="onFileChange"
              v-on:delete="deleteFile"
              v-model="file.excel_file"
              id="uploadfile"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-button size="md" class="btn-cancel" @click="hideImport()"
              >Cancel</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              id="upload"
              class="main-color btn-save color-btn"
              size="md"
              :disabled="isDisable"
              @click="importTemplate"
              >Upload</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";

export default {
  components: { UploadFileV2 },
  props: {},

  data() {
    return {
      isShow: false,
      form: {
        received_items: [],
        tax_invoice_no: "",
        tax_invoice_date: "",
        remark: "",
        po_product_id: "",
      },
      items: [],
      isBusy: false,
      fields: [
        { label: "ลำดับที่", key: "index", thStyle: { width: "10%" } },

        { label: "Serial No.", key: "serial", thStyle: { width: "10%" } },
        {
          label: "น้ำหนัก",
          key: "weight",
          thStyle: { width: "10%" },
        },
        {
          label: "ราคาทุน (ต่อชิ้น)",
          key: "cost",
          thStyle: { width: "20%" },
        },
        {
          label: "จำนวน",
          key: "quantity",
          thStyle: { width: "10%" },
        },
        { label: "รวมราคาทุน", key: "total_cost", thStyle: { width: "20%" } },
        { label: "Action", key: "action", thStyle: { width: "10%" } },
      ],
      selectedItem: {},
      id: this.$route.params.id,
      modalImport: false,
      file: {},
      isDisable: true,
      fileName: null,
    };
  },
  computed: {
    totalCost: function () {
      if (this.items.length == 0) {
        return 0;
      }

      let costSum = this.form.received_items.reduce(
        (sum, item) => sum + (Number(item.cost) || 0),
        0
      );
      if (this.selectedItem && !this.selectedItem.is_serial) {
        return this.items[0].cost * this.items[0].quantity;
      }
      return costSum;
    },
  },
  methods: {
    showModal(item) {
      this.isShow = true;
      this.selectedItem = { ...item };
      if (!item.is_serial) {
        this.items.push({
          index: this.items.length + 1,
          serial: "",
          weight: null,
          cost: null,
          quantity: item.quantity,
          total_cost: null,
          format_weight: null,
        });
        this.form.received_items = this.items;
      }
      this.form.po_product_id = item.id;
    },
    hide() {
      this.isShow = false;
      this.form = {
        received_items: [],
      };
      this.items = [];
      this.form = {};
    },
    async exportGoodReceipt(pdf) {
      const byteCharacters = window.atob(pdf);
      const byteNumbers = new Uint8Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const blob = new Blob([byteNumbers], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "GoodReceipt.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);
    },
    deleteFile() {
      this.file.excel_file = null;
      this.fileName = null;
      this.isDisable = true;
    },
    onFileChange(file) {
      this.fileName = file.name;
      this.file.document_no = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.file.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    async importTemplate() {
      try {
        this.$bus.$emit("showLoading");
        const data = await this.axios.post(`/po/importProductReceived`, {
          base64File: this.file.excel_file,
        });

        if (data.data.result === 1) {
          this.items = data.data.detail.map((x) => ({
            index: this.items.length + 1,
            serial: x.serial,
            weight: x.weight,
            cost: x.cost,
            quantity: x.quantity,
            total_cost: x.cost * x.quantity,
            format_weight: x.weight,
            format_cost: x.cost,
          }));
          console.log(this.items);

          this.form.received_items = this.items;
          console.log(this.form.received_items);
          this.successAlert();
        }
        this.hideImport();
      } catch {
        this.errorAlert("Import Fails");
      } finally {
        this.$bus.$emit("hideLoading");
      }
    },
    hideImport() {
      this.file = {};
      this.isDisable = true;
      this.fileName = null;
      this.modalImport = false;
    },
    async exportTemplate() {
      const res = await this.axios(`/po/ExportReceiveProductTemplate`);
      if (res.data.result === 1 && res.data.detail) {
        const byteCharacters = atob(res.data.detail);
        const byteNumbers = new Uint8Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const blob = new Blob([byteNumbers], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "ReceiveProductTemplate.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        URL.revokeObjectURL(url);
        this.successAlert();
      }
    },
    async save() {
      let payload = { ...this.form, po_id: this.id };
      const res = await this.axios.post(`/po/ReceiveProduct`, payload);
      if (res.data.result === 1) {
        await this.exportGoodReceipt(res.data.detail.document_base64);
        this.successAlert();
        this.$emit("updateReceiveProduct");
        this.isShow = false;
      } else {
        this.errorAlert(res.data.message);
      }
    },
    addItem() {
      if (this.selectedItem.received_available > 0) {
        this.items.push({
          index: this.items.length + 1,
          serial: null,
          weight: null,
          cost: null,
          quantity: 1,
          total_cost: null,
          format_weight: null,
        });

        this.form.received_items = this.items;
      }
    },
    deleteProduct(data) {
      this.items = this.items.filter((x) => x.index !== data.index);
      this.form.received_items = this.items;
    },
    handleKeyPress(event) {
      const char = event.key;

      if (!/[\d.,]/.test(char)) {
        event.preventDefault();
      }
      if (
        (char === "." && event.target.value.includes(".")) ||
        (char === "," && event.target.value.includes(","))
      ) {
        event.preventDefault();
      }
    },
    formatCurrencyWeght(val, e, index) {
      let value = val.replace(/[^0-9.]/g, "");

      if (value === "") {
        this.form.received_items[index].format_weight = "";
        this.form.received_items[index].weight = "";
        return;
      }

      const formattedValue = Number(value).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      });

      this.form.received_items[index].weight = value;
      this.form.received_items[index].format_weight = formattedValue;
    },
    formatCurrencyCost(val, e, index) {
      let value = val.replace(/[^0-9.]/g, "");

      if (value === "") {
        this.form.received_items[index].format_cost = "";
        this.form.received_items[index].cost = "";
        return;
      }

      const formattedValue = Number(value).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      });

      this.form.received_items[index].cost = value;
      this.form.received_items[index].format_cost = formattedValue;
    },
    limitQuantity(val, e, index) {
      if (val > this.selectedItem.received_available) {
        this.form.received_items[index].quantity =
          this.selectedItem.received_available;
        e.target.value = this.selectedItem.received_available;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-footer {
  justify-content: space-between;
}
.btn-save {
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.btn-cancel {
  color: #fff;
  background-color: #39424e;
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}

.input-tb ::v-deep input {
  text-align: center !important;
}

.card-dashed {
  border: 1px dashed;
  border-color: var(--primary-color);
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.text-width {
  width: 110px;
}

.custom-input ::v-deep textarea {
  width: 335px;
}
.custom-input-text {
  width: 215px;
}
</style>
