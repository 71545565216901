<template>
  <div>
    <b-modal
      v-model="modalShow"
      size="xl"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-header>Select Item</template>
      <b-row>
        <b-col>
          <InputSelect
            title="Level Category 1"
            name="type"
            class="mt-2"
            v-model.number="filter.ah1"
            v-bind:options="optionsCategory.list_ah1"
            valueField="ah_id"
            textField="ah_name"
            @onDataChange="selectCategory($event, 1)"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Category --</b-form-select-option
              >
              <b-form-select-option :value="0">All</b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
        <b-col>
          <InputSelect
            title="Level Category 2"
            name="type"
            class="mt-2"
            v-model.number="filter.ah2"
            v-bind:options="optionsCategory.list_ah2"
            valueField="ah_id"
            textField="ah_name"
            @onDataChange="selectCategory($event, 2)"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Category --
              </b-form-select-option>
              <b-form-select-option :value="0">All</b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
        <b-col>
          <InputSelect
            title="Level Category 3"
            name="type"
            class="mt-2"
            v-model.number="filter.ah3"
            v-bind:options="optionsCategory.list_ah3"
            valueField="ah_id"
            textField="ah_name"
            @onDataChange="selectCategory($event, 3)"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Category --</b-form-select-option
              >
              <b-form-select-option :value="0">All </b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
        <b-col>
          <InputSelect
            title="Level Category 4"
            name="type"
            class="mt-2"
            v-model.number="filter.ah4"
            v-bind:options="optionsCategory.list_ah4"
            valueField="ah_id"
            textField="ah_name"
            @onDataChange="selectCategory($event, 4)"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Category --</b-form-select-option
              >
              <b-form-select-option :value="0">All</b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
      </b-row>
      <b-input-group class="mt-2">
        <b-form-input
          placeholder="Search Product Name"
          v-model.trim="filter.search"
          @keyup.enter="handleSearch"
        ></b-form-input>
        <b-input-group-append>
          <b-input-group-text>
            <b-iconstack
              font-scale="2"
              type="submit"
              @click.prevent="handleSearch"
            >
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        class="mt-2"
        empty-text="No matching records found"
      >
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(product_image)="data">
          <div class="position-relative picture-text pic-table">
            <div
              v-if="data.item.product_image"
              class="pic-box"
              v-bind:style="{
                'background-image': 'url(' + data.item.product_image + ')',
              }"
            ></div>
            <div v-else>
              <img
                class="pic-box pt-0"
                :src="default_image"
                alt="default Image"
                srcset=""
              />
            </div>
          </div>
        </template>
        <template v-slot:cell(status)="{ item }">
          <!-- <div :class="item.status == '1' ? 'text-success' : 'text-danger'">
              {{ item.status ? "Active" : "Inactive" }}
            </div> -->
        </template>
        <template v-slot:cell(price)="{ item }">
          {{ item.price | numeral("0,0.00") }}
        </template>
        <template v-slot:cell(select)="{ item, index }">
          <b-form-group class="cb-table d-flex justify-content-center m-0">
            <b-form-checkbox
              :value="item.product_id"
              :checked="selectedProduct"
              @change="selectProductTable(item)"
            ></b-form-checkbox> </b-form-group
        ></template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="form-inline justify-content-center justify-content-sm-start mb-2 mb-sm-0"
        >
          <p class="mb-0 p-gray">
            Showing {{ showing === 1 ? showing : showing + 1 }} to
            {{ showingTo }} of {{ rows | numeral("0,0") }} entries
          </p>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="form-inline justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="filter.pageNo"
            :total-rows="rows"
            :per-page="filter.length"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
            class="m-md-0"
            @change="pagination"
          ></b-pagination>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <b-button @click.prevent="hide" class="border-btn">Cancel</b-button>

          <b-button
            class="submit-btn text-right"
            @click.prevent="submitSelectProduct"
            >Select Items</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import InputSelect from "@/components/inputs/InputSelect";

export default {
  components: {
    InputSelect,
  },
  props: {
    selectedItem: {
      type: Array,
    },
    isApprove: {
      type: Boolean,
    },
  },
  data() {
    return {
      modalShow: false,
      isBusy: false,
      fields: [
        {
          key: "select",
          label: "",
          thClass: "w-1",
        },
        {
          key: "product_image",
          label: "Image",
          thClass: "w-2",
        },
        {
          key: "name",
          label: "Name",
          thClass: "w-2",
        },
        {
          key: "barcode",
          label: "Barcode",
          thClass: "w-2",
        },
        {
          key: "article_no",
          label: "Article No.",
          thClass: "w-2",
        },
        {
          key: "price",
          label: "Price",
          thClass: "w-2",
        },
        // {
        //   key: "status",
        //   label: "Status",
        //   thClass: "w-2",
        // },
      ],
      showing: 1,
      showingTo: 5,
      filter: {
        search: "",
        pageNo: 1,
        start: 0,
        length: 5,
        ah1: 0,
        ah2: 0,
        ah3: 0,
        ah4: 0,
        add_product_id: [],
        delete_product_id: [],
      },

      rows: 0,
      optionsCategory: {
        list_ah1: [],
        list_ah2: [],
        list_ah3: [],
        list_ah4: [],
      },

      items: [],
      selectedProduct: [],
      formProduct: [],
      isLoading: false,
      mapProduct: [],
    };
  },
  methods: {
    async show() {
      this.modalShow = true;
      this.getProduct();
      this.getCategory();
      this.selectedProduct = this.selectedItem.map((x) => x.product_id);
      this.formProduct  = [...this.selectedItem]
    },
    async getProduct() {
      this.isBusy = true;
      const res = await this.axios.post(
        `ProductMenu/GetCollectionProductListByCategory`,
        this.filter
      );

      this.rows = res.data.detail.total_product;
      this.items = res.data.detail.product_list.map((x) => ({
        name: x.name,
        product_id: x.id,
        barcode: x.barcode,
        article_no: x.article_no,
        price: x.price,
        product_image: x.imageUrl,
        manufacturer_product_code: "",
        is_serial: null,
        manufacturer_product_code_id: 0,
        quantity: 1,
        total_price: x.price,
        received_available: 0,
        received_quantity: 0,
        remark: "",
        transfered_quantity: 0,
      }));

      this.isBusy = false;
    },
    async getCategory() {
      this.isBusy = true;
      const res = await this.axios.post(
        `ProductMenu/GetListByCategory `,
        this.filter
      );
      this.optionsCategory = {
        list_ah1: res.data.detail.list_ah1,
        list_ah2: res.data.detail.list_ah2,
        list_ah3: res.data.detail.list_ah3,
        list_ah4: res.data.detail.list_ah4,
      };

      this.isBusy = false;
    },
    pagination(value) {
      this.filter.pageNo = value;
      if (value === 1) {
        this.showing = this.filter.pageNo;
        this.showingTo = this.filter.length;
      } else {
        this.showing = value * this.filter.length - this.filter.length;
        this.showingTo = this.showing + this.filter.length;
      }
      this.getProduct();
    },
    handleChangeTake() {
      this.getProduct();
    },
    handleSearch() {
      this.filter.pageNo = 1
      this.getProduct();
    },
    selectProductTable(product) {
      const index = this.selectedProduct.findIndex((item) => item === product.product_id);
      if (index !== -1) {
        this.selectedProduct.splice(index, 1);
        this.formProduct.splice(index, 1);
      } else {
        this.selectedProduct.push(product.product_id);
        this.formProduct.push(product)
      }
    },

    async submitSelectProduct() {
      this.filter = {
        pageNo: 1,
        start: 0,
        length: 5,
        ah1: 0,
        ah2: 0,
        ah3: 0,
        ah4: 0,
        add_product_id: [],
        delete_product_id: [],
      };
      this.modalShow = false;
      this.$emit("success", this.formProduct);
    },
    selectCategory() {
      this.getProduct();
      this.getCategory();
    },
    hide() {
      this.filter = {
        pageNo: 1,
        start: 0,
        length: 5,
        ah1: 0,
        ah2: 0,
        ah3: 0,
        ah4: 0,
        add_product_id: [],
        delete_product_id: [],
      };
      this.modalShow = false;
    },
  },
};
</script>
<style lang="scss">
.pic-box {
  margin: auto !important;
  width: 50%;
  padding-top: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
